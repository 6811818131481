
import Vue from "vue";
import DatePicker from "@/components/form-components/DatePicker.vue";
import store from "@/store";
import API from "@/api/API";
import rules from "@/services/helpers/validation-rules";
import { mask } from "vue-the-mask";
import ClientData from "@/modules/orders/components/ClientData.vue";
import Documents from "@/modules/orders/components/Documents.vue";
import Services from "@/modules/orders/components/Services.vue";
import Client from "@/modules/orders/mixins/client";

export default Vue.extend({
  name: "AtaCreateOrder",

  components: {
    Services,
    ClientData,
    DatePicker,
    Documents
  },

  mixins: [Client],

  props: {
    language: {
      type: String,
      default: "ru",
      required: true
    },
    serviceId: {
      required: true,
      type: Number
    },
    allocations: {
      required: true,
      type: Object
    }
  },

  directives: { mask },

  data: () => ({
    rules,
    loading: false as boolean,
    isSelectBeneficiary: false as boolean,
    selectedCard: null as null | SelectComponentInterface,
    model: {
      documents: [] as Array<any>,
      supporting_documents: {
        acts_of_proof: [] as Array<any>,
        authority: [] as Array<any>,
        other: [] as Array<any>
      } as any,
      details: { services: [] as Array<any> } as any,
      order: {
        use_last_power_of_attorney: false as boolean,
        source: "operator",
        client_delegate: "",
        document_type: "new",
        client: { type: "" } as any,
        document_valid_from: new Date().toISOString().substr(0, 10),
        power_of_attorney: "beneficiary" as string
      } as any
    } as any,
    errorMessages: {} as any,
    selects: {
      cardTypes: [] as Array<SelectComponentInterface>,
      purpose: [] as Array<SelectComponentInterface>,
      packingCategories: [] as Array<SelectComponentInterface>,
      languages: [] as Array<SelectComponentInterface>,
      client_types: [] as Array<SelectComponentInterface>,
      release_types: [] as Array<SelectComponentInterface>,
      areas: ["agro", "other"] as Array<string>
    } as any,
    lang: store.getters["localization/getCurrent"],
    selectedTab: 0,
    totalSum: 0 as number,
    createBeneficiaryModal: false as boolean,
    isLoading: false,
    breadcrumb: [
      {
        text: "orders.title",
        disabled: false,
        href: "/orders"
      },
      {
        text: "orders.form.header.create",
        disabled: true,
        href: `/orders/edit`
      }
    ],
    defaultCountry: null as any,
    forceKey: 0 as number,
    isCreate: true as boolean,
    powerOfAttorney: null as any
  }),

  watch: {
    language: {
      immediate: true,
      handler() {
        this.model.order.language = this.language;
      }
    },
    "model.documents": {
      immediate: true,
      deep: true,
      handler() {
        this.$forceUpdate();
      }
    }
  },

  computed: {
    isOriginalDocument() {
      return this.model.order.document_type === "new";
    },
    areas() {
      return this.selects.areas.map((item: any) => ({
        value: item,
        text: this.$t(`orders.areas.${item}`, this.model.order.language)
      }));
    },
    isClassic() {
      return this.model.order.power_of_attorney === "classic";
    },
    parentLanguage() {
      return this.model.order.language === "ro";
    }
  },

  async mounted() {
    await this.loadData();
  },

  methods: {
    loadDocuments(val: any) {
      this.model.documents = val;
    },
    getOrderSum(event: any) {
      this.totalSum = event;
    },
    async loadData() {
      this.loading = true;
      try {
        const headers = {
          "x-localization": this.language
        };
        const response = await Promise.all([
          API.purposeOfUse().getList(this.serviceId, headers),
          API.orders().getOrderReleaseModes(),
          API.clients().getClientTypes(),
          API.orders().getCardTypes(this.serviceId),
          API.orders().getLanguages(),
          API.branches().getList()
        ]);
        await this.setServerResponse(response);
      } catch (e) {
        await store.dispatch("alert/showError", e.message);
      }
      this.loading = false;
    },
    async submit(): Promise<void> {
      const form = this.$refs.form as Vue;
      this.isLoading = true;

      try {
        if (form.validate()) {
          this.model.documents = this.model.documents.filter(
            (item: any) => item.file_type !== "confirm_represent_power"
          );

          if (this.powerOfAttorney?.file) {
            this.model.documents.push(this.powerOfAttorney);
          }

          const model = { ...this.model };
          model.order.service_type_id = this.serviceId;

          const { branch_id, subdivision_id, handler_id } = this.allocations;
          model.order.branch_id = branch_id;
          model.order.subdivision_id = subdivision_id;
          model.order.handler_id = handler_id;

          await this.$API.orders().create(model);
          form.removeGuard();
          await this.$router.push("/orders");
          await this.$store.dispatch(
            "alert/showSuccess",
            this.$t("global_alert.successful_create")
          );
        } else {
          this.selectedTab = 0;
          await setTimeout(() => {
            const firstErrorInput = form.inputs.find(
              (item: any) => item.messagesToDisplay.length
            );

            if (firstErrorInput) {
              this.$vuetify.goTo(firstErrorInput.$el, {
                offset: 150
              });
            }
          }, 500);
        }
      } catch (e) {
        form.removeGuard();
        if (e.hasOwnProperty("errors")) {
          this.errorMessages = e.errors;

          await setTimeout(() => {
            const firstErrorInput = form.inputs.find(
              (item: any) => item.messagesToDisplay.length
            );
            if (firstErrorInput) {
              this.$vuetify.goTo(firstErrorInput.$el, {
                offset: 150
              });
            }
          }, 500);
          setTimeout(() => {
            this.errorMessages = {};
          }, 4000);
        }
        await this.$store.dispatch("alert/showError", e.message || e);
      }
      this.isLoading = false;
    },
    async setServerResponse(response: Array<any>) {
      const [
        purpose,
        releaseModes,
        clientTypes,
        cardTypes,
        languages,
        branches
      ] = response;
      this.selects.languages = languages.items;
      this.selects.purpose = purpose;
      this.selects.release_types = releaseModes.items;
      this.model.order.release_mode = releaseModes.default;
      this.selects.client_types = clientTypes;
      this.selects.cardTypes = cardTypes.items;
      this.model.order.document_type = cardTypes.default;
      this.selects.branches = branches;
    },
    setPurposeDescription() {
      const index = this.selects.purpose.findIndex(
        (item: any) => item.value === this.model.details.purpose_id
      );
      if (index !== -1 && !this.model.details.purpose_description) {
        this.model.details.purpose_description = this.selects.purpose[
          index
        ].description;
      }
    }
  }
});
